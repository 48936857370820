<template>
    <div class="studentplan">

        <div class="studentplan_child">
            <div class="studentplan_child_header">
                <div class="studentplan_child_header_left">
                </div>
                <div class="studentplan_child_header_right">
                    <div>
                        梯到必修课，必须看的课程，非常值得拥有
                    </div>
                    <div>
                        数字化供应链管理课程
                    </div>
                    <div>
                        课程标签
                    </div>
                    <div>
                        截至时间：2023-12-30 &nbsp;&nbsp;&nbsp; 发布时间:2023-1-1
                    </div>
                    <div>
                        加入计划
                    </div>
                </div>
            </div>
            <div>
                <div style=" margin-top: 3em;" class="satisfaction">
                    <el-radio-group v-model="satisfaction" class="ml-4">
                        <el-radio label="1" size="large"><img src="../assets/leaning/differ.png"
                                alt="">&nbsp;&nbsp;非常不满意</el-radio>
                        <el-radio label="2" size="large"><img src="../assets/leaning/letterdiffer.png"
                                alt="">&nbsp;&nbsp;不满意</el-radio>
                        <el-radio label="3" size="large"><img src="../assets/leaning/nomal.png"
                                alt="">&nbsp;&nbsp;一般</el-radio>
                        <el-radio label="4" size="large"><img src="../assets/leaning/good.png"
                                alt="">&nbsp;&nbsp;满意</el-radio>
                        <el-radio label="5" size="large"><img src="../assets/leaning/verygood.png"
                                alt="">&nbsp;&nbsp;非常满意</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="directory">
                <div class="directory_left">
                    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                        <el-tab-pane label="目录" name="first">
                            <div class="demo-collapse">
                                <el-collapse v-model="activeNames" @change="handleChange" >
                                    <el-collapse-item :title="`第${item}章 工作台`" :name="item" v-for="item in 6">
                                        <div style="padding:0px 30px;" class="demo_collapse_child" v-for="item in 3"> 
                                            <div>
                                                <div>1. 梯到，工作台讲解</div>
                                                <div style="margin: 5px 0px; display: flex; align-items: center;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-icon><VideoPlay /></el-icon>视频&nbsp;  <span>12分20秒</span></div>
                                            </div>
                                            <div class="gotostudent">
                                                去学习
                                            </div>
                                        </div>
                                    </el-collapse-item>
                                   
                                </el-collapse>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="简介" name="second">
                            入梯到商家必看的管理课程，你值得拥有
                        </el-tab-pane>
                        <el-tab-pane label="课程评价" name="three">
                                <div style="box-sizing: border-box; ">
                                    <el-input type="textarea" :rows="6" v-model="comment">

                                    </el-input>
                                </div>
                                <div
                                    style="display: flex; justify-content: end; align-items: center;font-size: 1.2em;padding-right: 10px;margin-top: 1em;">
                                    0/15&nbsp;&nbsp;&nbsp;<el-button type="primary"> 评论</el-button>
                                </div>
                                <div class="commit_cont" v-for="item in 6" style="margin-top: 3em;">
                                    <div class="commit_cont_img">
                                        <img src="../assets/wechat_login.png" alt="">
                                    </div>
                                    <div style="line-height: 25px; padding-left: 20px;">
                                        <div style="color: #999;">张***非</div>
                                        <div>哈哈哈哈</div>
                                        <div style="color: #999;">10分钟前</div>

                                    </div>
                                </div>
                            </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="directory_right">
                    <div style="font-size: 1.3em; padding-bottom: 20px;">
                        相关课程
                    </div>
                    <div class="about_course" v-for="item in 3">
                        <div class="about_course_header">
                        </div>
                        <div class="about_course_title">电批啊实打实啊实打实的，氨基酸大苏打</div>
                        <div class="course_author">
                            <div>免费</div>
                            <div>
                                <img src="../assets/wechat_login.png" alt=""
                                    style="width: 20px; height: 20px; border-radius: 50%;" />
                                程普
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { VideoPlay } from '@element-plus/icons-vue'
const satisfaction = ref("")


const activeNames = ref(['1'])
const handleChange = (val) => {
  console.log(val)
}
const activeName = ref('first')

</script>

<style scoped lang="scss">
.studentplan {
    width: 100%;
    min-height: 1200px;
    display: flex;
    justify-content: center;
    background-color: white;

    .studentplan_child {
        width: 70%;
        min-height: 1200px;

        .studentplan_child_header {
            width: 100%;
            min-height: 22em;
            border: 1px solid gainsboro;
            display: flex;

            .studentplan_child_header_left {
                flex: 2;
                height: 22em;
                background: url('../assets/leaning/studentplan.png') no-repeat;
                background-size: 100% 100%;
            }

            .studentplan_child_header_right {
                flex: 3;
                padding: 30px;

                div:nth-child(1) {
                    font-size: 1.6em;
                    height: 2em;
                }

                div:nth-child(2) {
                    height: 3em;

                }

                div:nth-child(3) {
                    height: 3em;

                }

                div:nth-child(4) {
                    height: 2em;
                    color: #8c8c8c;
                }

                div:nth-child(5) {
                    height: 2.5em;
                    width: 6.5em;
                    margin-top: 20px;
                    background-color: #EA2323;
                    border-radius: 5px;
                    color: #8c8c8c;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                }
            }

        }

        .satisfaction {
            width: 100%;
            height: 50px;
            background-color: #f1f1f1;
            display: flex;
            align-items: center;
            padding-left: 10px;

            img {
                vertical-align: middle;
            }
        }

        .directory {
            display: flex;

            .directory_left {
                margin-top: 20px;
                flex: 7;
                border-right: 1px solid gainsboro;
                min-height: 1200px;
                .demo_collapse_child{
                    display: flex;
                    height: 80px;
                    justify-content: space-between;
                    align-items: center;
                    .gotostudent{
                        width: 7em;
                        height: 2.5em;
                        color: red;
                        border: 1px solid red;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                }
                .demo_collapse_child:hover{
                    background-color: #f8f8f8;
                }
                :deep(.el-collapse-item__header){
                    font-size: 20px;
                }
                .commit_cont {
                        display: flex;
                        height: 8em;

                        box-sizing: border-box;
                        border-bottom: 1px solid #f2f2f2;

                        .commit_cont_img {
                            width: 30px;
                            height: 30px;

                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }

                        }
                    }
            }
            :deep(.el-collapse){
                border: none;
            }
            :deep(.el-collapse-item__header){
                border: none;
            }
            .directory_right {
                flex: 2;
            }
            .directory_right {
                flex: 2;
                padding: 10px;

                .about_course {
                    width: 200px;
                    height: 188px;
                    margin-bottom: 10px;

                    .about_course_header {
                        width: 100%;
                        height: 120px;
                        background: url('../assets/leaning/video1.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .about_course_title {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        font-size: 1.2em;
                        padding: 5px;
                    }

                    .course_author {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 5px;

                        img {
                            vertical-align: middle;
                        }
                    }
                }

                .about_course:hover {
                    box-shadow: 5px 5px 10px gainsboro;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>